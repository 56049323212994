<template>
  <div class="plugin">
    <div>
      <h2 class="title_plugin">Plugin Plans</h2>
      <div class="plugin_plans_text title is-centered has-text-black has-text-centered">
        <p>Plugins are available in Free and Custom plans Only</p>
        <p>For white-labelled custom plans, please contact us </p>
      </div>
    </div>
    <div class="has-text-centered has-text-black has-text-weight-bold ">
      Please check our 
      <a href="https://docs.jdoodle.com/integrating-compiler-ide-to-your-application" target="_blank">Documentation</a>
        for integration details.
    </div>
    <DoodleMessage class="has-text-grey-dark" :message="doodleForm.errorMessage" message-class="has-text-danger"
      v-show="doodleForm.errorMessage" />
    <div>

    </div>
<div class="plugin_demo">
  <div class=" has-text-weight-bold  margin-top-20px margin-bottom-10px">Custom Plugins
    Demo #1</div>
  <img src="../assets/images/plugin/img1.png" >
</div>
<div class="plugin_demo">
  <div class=" has-text-weight-bold  margin-top-20px margin-bottom-10px">Custom Plugins
    Demo #2</div>
  <img src="../assets/images/plugin/img2.png" >
</div>
<div class="plugin_demo">

  <p class=" has-text-weight-bold  margin-top-20px margin-bottom-10px">It just takes 3 lines of HTML to integrate our plugin to your site</p>
    <div class="box plugin-code-sample" style="width: 100%;">
      
      <pre>
&lt;body>
  <b>&lt;div data-pym-src='https://www.jdoodle.com/plugin' data-language="java"
    data-version-index="4" data-libs="mavenlib1, mavenlib2"></b>
    Optional default code goes here
  <b>&lt;/div></b>
  <b>&lt;script src="https://www.jdoodle.com/assets/jdoodle-pym.min.js" type="text/javascript">&lt;/script></b>
&lt;/body>
      </pre>
    </div> 

</div>

  </div>
</template>

<script>
import formMixin from '../assets/javascript/form-mixin'
import DoodleMessage from '../components/Utils/DoodleMessage' 

export default {
  name: 'home',
  mixins: [formMixin],
  components: {
    DoodleMessage, 
  },
 
}
</script>

<style scoped lang="scss">
.plugin{
  padding-bottom: 50px;
  .title_plugin {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  margin-bottom: 60px;
  margin-left: 170px;
}

.plugin_plans_text {
  margin-bottom: 20px;
}
.plugin_demo{
  width: 70%;
  margin: 20px auto;
  img{
    width: 100%;
  }
}
.sub-title {
  font-size: 0.5em;
  font-style: italic;
  color: tomato;
  padding: 0.2em;
}

ul {
  flex-wrap: wrap;
  max-width: 95%;
}

li {
  padding: 0.3em;
}

.li-points {
  margin-left: 1em;
  font-size: 0.9em;
  font-weight: bold;
}

.tabs a {
  background-color: #f5f5f5;
  border-color: #b5b5b5;
}

article {
  @media screen and (max-width: 769px) {
    padding-left: 1px;
    padding-right: 1px;
  }
}

.doc-link {
  text-decoration: underline;
  margin-top: -15px;
  margin-bottom: 10px;
}

.plugin-info-box-left {
  padding: 2.5em;
  font-size: 1.5em;
  text-align: center;
  font-weight: 500;
}

.plugin-info-box-right {
  padding: 1.2em;
  font-size: 1em;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  line-height: 2em;

  .button {
    margin-top: 1em;
  }

  div {
    background: white;
  }
}

.plugin-info-box {
  margin: 5em;
  width: calc(100% - 10em);
  padding: 0;
  background: #f5f5f5;
}

.plugin-code-sample {
  background-color: #eee;
  border: 1px solid #999;
  display: block;
  padding: 20px;
  width: 70%;
  margin: auto auto;
  margin-top: 20px;
}
  
}

</style>
